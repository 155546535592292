<template>
  <!--  TODO уточнить что такое темы-->
  <div class="font-base theme theme_default">
    <div v-if="isLoading"><PageSpinner /></div>
    <component :is="$route.meta?.['layout']">
      <router-view />
    </component>

    <AllModals />
    <CookiesBanner />
  </div>
</template>

<script lang="ts">
import { EX_$Observer } from '@/classes/base';

export default {
  name: 'App',
  created() {
    EX_$Observer.context = this;
    EX_$Observer.setContext(this);
  },
};
</script>

<script setup lang="ts">
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import AllModals from '@/components/modals/all-modals/AllModals.vue';
import CookiesBanner from '@/components/shared/banners/cookies/CookiesBanner.vue';
import store from '@/store';
import PageSpinner from '@/components/layouts/page-spinner/PageSpinner.vue';
import { useRoute } from 'vue-router/composables';
import { EX_$Modal } from '@/classes/content';
import { Fingerprint } from '@/helpers/classes/fingerprint.class';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { useFavicon, useTitle } from '@vueuse/core';

const route = useRoute();

const authStore = useAuthStore();
const windowSizeStore = useWindowSizeStore();

const isLoading = computed(() => {
  return store.state.client.isFetchClientInfo || store.state.auth.isRefreshing;
});

const initModalsFromQuery = async () => {
  const query = route.query;
  if (query.isRecoverPasswordModalOpen === '1') {
    await EX_$Modal.toggleModal('appModalRecoveryPassword');
    await store.dispatch('auth/ASetRecoverToken', query.recoverToken);
  }
  if (query.appModalRegistration === '1') {
    await EX_$Modal.toggleModal('appModalRegistration');
  }
};

watch(
  () => store.state.auth.isLogin,
  async (value) => {
    if (value) {
      await store.dispatch('event/AGetFavoriteEvents');
      await store.dispatch('profile/AGetProfile');
    }
  }
);

// TODO удалить/доработать после теста
const addScript = () => {
  // добавляем скрипт из яндекс стораджа в head.
  // TODO доработать: хранить список пикселей/скриптов в бд
  const scriptElement = document.createElement('script');
  scriptElement.src =
    'https://storage.yandexcloud.net/staging-mmco-uploads-20240602154708777100000001/test-pixel.js';
  document.body.appendChild(scriptElement);
};

onMounted(async () => {
  windowSizeStore.init();
  await Fingerprint.init();
  await store.dispatch('client/AGetInfo');
  useTitle(store.state.client.clientInfo?.header?.title);
  useFavicon(store.state.client.clientInfo?.header?.favIconUrl);
  await authStore.checkAccessTokenFirstTime();
  await store.dispatch('event/AGetMyEventList');
  await initModalsFromQuery();

  // TODO удалить/доработать после теста
  // TODO доработать: присылать список пикселей/скриптов в clientInfo, если они есть, добавлять их в head
  if (store.state.client.clientInfo?.clientUUID === 'b267e7e9-2300-4f2c-8f60-71e64ef502cd') {
    addScript();
  }
});
onBeforeUnmount(() => {
  windowSizeStore.cleanup();
});
</script>

<style lang="scss">
@use '@/ui/components/toast/style.scss';

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container {
  max-width: 1224px;
  margin: 0 auto;
  padding: 0 16px;
}
body.lock {
  overflow: hidden;
  padding-right: 0;
}

:root {
  --header-color: #ffffff;
}

* {
  padding: 0;
  margin: 0;
  border: none;
}
body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
main {
  overflow-x: clip;
  flex: 1 0 auto;
  // отступы чтобы хедер не наезжал на контент
  padding-top: 62px;
  //@media (max-width: 768px) {
  //  padding-top: 62px;
  //}
}
a,
a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul,
ul li {
  list-style: none;
}
pre {
  white-space: pre-line;
  margin: 0;
  display: flex;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
